import ProfilesContextProvider, { IUseProfilesProps } from '@/openMarket/application/Finsight/ProfilesContext';
import Profiles from '@/openMarket/ui/components/Finsight/Profiles';
import withFinsightGlobalProps from '@/finsight/application/withFinsightGlobalProps';
import getServerSideOpenMarketBreadcrumbs from '@/openMarket/application/Finsight/serverSide/getServerSideOpenMarketBreadcrumbs';
import UserPlan, { UserPlanType } from '@/users/domain/UserPlan';
import { getServerSideProfilesList } from '@/openMarket/application/Finsight/serverSide/getServerSideProfilesList';
import IBreadcrumb from '@/finsight/domain/vo/Breadcrumbs';
import RequiredUserPlan from '@/openMarket/ui/common/Finsight/RequiredUserPlan';
import ComingSoon from '@/openMarket/ui/common/Finsight/ComingSoon';
import { useCheckUserPlan } from '@/finsight/ui/common/components/CheckUserPlanAccess/useCheckUserPlan';
import { useFeatureToggleContext } from '@/Framework/UI/Organisms/FeatureToggle/Context';
import { OPENMARKET } from '@/finsight/application/config/featureToggle';

export interface IServerSideProfilePageReturnProps {
  props: IUseProfilesProps & { breadcrumbs: IBreadcrumb[] },
}

export const getServerSideProps = withFinsightGlobalProps(
  async ({ userPlanState, container, globalProps }): Promise<IServerSideProfilePageReturnProps> => {
    const hasPermission = UserPlan.isSatisfiedStarterPlan(userPlanState.plan);
    const breadcrumbs = getServerSideOpenMarketBreadcrumbs();

    const { isEnabled } = globalProps.featuresConfig?.[OPENMARKET] ?? { isEnabled: false };
    const isPageHiddenFromUser = !isEnabled;

    if (!hasPermission || isPageHiddenFromUser) {
      return {
        props: {
          profiles: {
            collection: [],
            totalCount: 0,
          },
          hasServerError: true,
          breadcrumbs,
        },
      };
    }

    const { profiles, hasServerError } = await getServerSideProfilesList({ container });

    return {
      props: {
        profiles,
        hasServerError,
        breadcrumbs,
      },
    };
  },
);

const ProfilePage = (props: IUseProfilesProps) => {
  const hasPermission = useCheckUserPlan(UserPlanType.STARTER);
  const { getFeatureByName } = useFeatureToggleContext();
  const { isEnabled } = getFeatureByName(OPENMARKET);
  const isPageHiddenFromUser = !isEnabled;

  if (isPageHiddenFromUser) {
    return <ComingSoon />;
  }

  if (!hasPermission) {
    return <RequiredUserPlan />;
  }

  return (
    <ProfilesContextProvider { ...props }>
      <Profiles />
    </ProfilesContextProvider>
  );
};

export default ProfilePage;
