import { createContext, useContext } from 'react';
import useProfiles, { IProps as IUseProfilesProps } from './useProfiles';

type TProfilesContext = ReturnType<typeof useProfiles>;

export const ProfilesContext = createContext<TProfilesContext>(null);

export const useProfilesContext = () => {
  const context = useContext(ProfilesContext);
  if (!context) {
    throw new Error('useProfilesContext must be used within a ProfilesContextProvider');
  }
  return context;
};

interface IProps extends IUseProfilesProps {
  children: React.ReactNode,
}

const ProfilesContextProvider = ({ children, ...props }: IProps) => (
  <ProfilesContext.Provider value={ useProfiles(props) }>
    { children }
  </ProfilesContext.Provider>
);

export default ProfilesContextProvider;
