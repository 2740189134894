import cn from 'classnames';

import Image from '@/Framework/Router/Next/Image';
import { Link } from '@/Framework/Router/Next/Link';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { ViewType } from '@/openMarket/application/Finsight/ProfilesContext/constants';
import { toSlug } from '@/Framework/url/helpers/toSlug';
import { IProfile } from '@/openMarket/domain/vo/IProfile';

import styles from './profileItem.scss';

interface IProps {
  profile: IProfile,
  viewType: ViewType,
  isMobile: boolean,
}

const ProfileItem = ({ profile, viewType, isMobile }: IProps) => {
  return (
    <div
      data-test="profileItem"
      className={ cn({
        [styles.profileGridType]: viewType === ViewType.GRID || isMobile,
        [styles.profileListType]: viewType === ViewType.LIST && !isMobile,
      }) }
    >
      <div className={ styles.logoWrapper }>
        <Image
          src={ profile.headerLogo.url }
          alt={ profile.name }
          title={ profile.name }
          height={ 30 }
          width={ 150 }
          className={ styles.logo }
          priority
        />
      </div>
      <div className={ styles.body }>
        <div>
          <h5 className={ styles.companyName } data-test="profileName">
            { profile.name }
          </h5>
          <p className={ styles.description } data-test="profileDescription">
            { profile.headerDescription }
          </p>
        </div>
        <Link to={ `/profile-${ profile.viewId }-${ toSlug(profile.name) }` }>
          <Button variant={ variantTypes.action } className={ styles.button } dataTest="viewProfileButton">
            View Profile
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ProfileItem;
