import { useDIContext } from '@/Framework/DI/DIContext';
import ProfileRepository from '@/openMarket/infrastructure/repository/Finsight/ProfileRepository';
import { useBufferedFetchCollection } from '@/openMarket/ui/hooks/useBufferedFetchCollection';
import { IProfile } from '@/openMarket/domain/vo/IProfile';

import { PROFILE_VISIBLE_QUANTITY } from './constants';

export interface IProps {
  profiles: {
    collection: IProfile[],
    totalCount: number,
  },
  hasServerError: boolean,
}

const useProfiles = ({ profiles, hasServerError }: IProps) => {
  const { container } = useDIContext();
  const openMarketRepository = container.get<ProfileRepository>(ProfileRepository);

  return useBufferedFetchCollection({
    fetcher: openMarketRepository.getProfiles,
    perPage: PROFILE_VISIBLE_QUANTITY,
    initial: !hasServerError ? profiles : null,
  });
};

export default useProfiles;
